<template>
  <div>
    <div class="container" v-if="device === 'mobile'">
      <RoomOptions
        v-for="(item, index) in roomsList"
        :key="index"
        :data="item"
        :index="index"
        @closed="removeItem"
        @update="updateItem"
        @setPriceLoading="getPriceLoading"
        @updatedActiveHotel="updatedActiveHotel"
      />
      <div class="chooseroom_footer">
        <div class="chooseroom_footer_top d-flex justify-content-between">
          <a href="#" onclick="return false;" @click="addRoom" class="btn btn-link" :disable="priceLoading">
            <b-spinner small type="grow" v-if="priceLoading"></b-spinner>
            <i class="fas fa-plus" v-else></i> {{ $t('product-page.add-room') }}
          </a>
          <div class="pricebox">
            {{ $t('product-page.total-pay') }}
            <strong>${{ totalPrice }}</strong>
          </div>
        </div>
        <a href="#" @click="gotoBook" class="btn btn-primary d-block w-100 mt-3">
          {{ $t('product-page.order') }}
        </a>
      </div>
    </div>
    <div class="vacationprice_area" v-else>
      <h3>
        {{ packageType }}
      </h3>
      <div class="flight-details">
        <div class="hldr">
          <div class="row">
            <div class="col-lg-8 col-md-8">
              <div class="flight-details-leftbox">
                <h4 :style="lang !== 'he' ? 'direction: ltr;' : ''">
                  {{ personString }}, <span dir="ltr">{{ daysString }} </span>
                </h4>
                <div class="card cardone">
                  <RoomOptions
                    v-for="(item, index) in roomsList"
                    :key="index"
                    :data="item"
                    :index="index"
                    @closed="removeItem"
                    @update="updateItem"
                    @setPriceLoading="getPriceLoading"
                    @updatedActiveHotel="updatedActiveHotel"
                  />
                  <button type="button" class="add-field" @click="addRoom" :disabled="addRoomDisable || priceLoading">
                    <i class="far fa-plus-square"></i>
                    <b-spinner v-if="priceLoading" small type="grow"></b-spinner>
                    {{ $t('product-page.add-room')}}<i class="fas fa-hospital-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="flight-details-leftbox">
                <h4>{{ $t('product-page.vacation-price') }}</h4>
                <div class="card cardtwo">
                  <RoomPrice
                    v-for="(item, index) in roomsList"
                    :key="index"
                    :room="item"
                    :index="index"
                    @closed="removeItem"
                  />
                  <div class="card-footer">
                    <div class="d-flex justify-content-between">
                      <div class="p-2">
                        {{ $t('product-page.special-price') }}
                      </div>
                      <div class="p-2">${{ totalPrice }}</div>
                    </div>
                  </div>
                </div>
                <div class="addfooterbox d-flex justify-content-between">
                  <p><img :src="`${imageDomain}/assets/img/breach.jpg`" alt="breach" width="500" height="200" /></p>
                  <button
                    class="btn btn-primary"
                    :disabled="priceLoading"
                    @click="gotoBook"
                  >
                    {{ $t('product-page.book-button') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { BSpinner } from 'bootstrap-vue';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  components: {
    RoomOptions: () => import('@/components/productPage/RoomOptions'),
    RoomPrice: () => import('@/components/productPage/RoomPrice'),
    BSpinner,
  },
  props: {
    hotel: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      roomsList: [],
      maxRoomCount: 5,
      addRoomDisable: false,
      priceLoading: false,

      adult: 0,
      child: 0,
      infant: 0,

      personString: '',
      daysString: '',

      totalPrice: 0,
      averagePrice: 0,
    };
  },
  watch: {
    lang() {
      this.personString = this.getPersonString(
        this.adult,
        this.child,
        this.infant,
      );
    },
    roomsList() {
      this.addRoomDisable = this.roomsList.length === this.maxRoomCount;
      this.updateTotalPrice();
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      product: 'GET_PRODUCT',
    }),
    packageType() {
      return `${this.$t('product-page.hotel-price')} ( ${this.$t(
        'product-page.hotel',
      )} )`;
    },
  },
  created() {
    this.roomsList.push(this.getOneItem());
    this.initTourist();
    this.personString = this.getPersonString(
      this.adult,
      this.child,
      this.infant,
    );
    this.daysString = `${dayjs(this.hotel.stay.checkIn).format(
      'D.M',
    )} - ${dayjs(this.hotel.stay.checkOut).format('D.M')}`;
  },
  methods: {
    initTourist() {
      if (this.hotel.categoryId === 'Hotel_Only') {
        this.adult = this.hotel.occupancies[0].adults;
        this.child = this.hotel.occupancies[0].children;
      }
    },
    getPersonString(adult = 0, child = 0, infant = 0) {
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    async addRoom() {
      if (!this.addRoomDisable) {
        this.roomsList.push(this.getOneItem());
      }
    },
    getOneItem() {
      const oldData = {
        index: this.index,
        hotelId: '',
        roomClass: null,
        roomType: null,
        roomBasis: '',
        roomBasisCode: '',
        price: 0,
        adult: 0,
        child: 0,
        infant: 0,
        totalPrice: 0,
        oldPrice: 0,
        oldTotal: 0,
      };
      return {
        ...oldData,
        currency: this.hotel.currency,
        stay: this.hotel.stay,
        occupancy: this.hotel.occupancies[0],
        hotelId: this.product.activeHotel[0].code,
      };
    },
    removeItem(index) {
      this.roomsList.splice(index, 1);
    },
    async updateItem(priceInfo) {
      const indRoom = priceInfo.index;
      this.roomsList[indRoom] = Object.assign(
        this.roomsList[indRoom],
        priceInfo,
      );
      this.updateTotalPrice();
    },
    getPriceLoading(isLoading) {
      this.priceLoading = isLoading;
    },
    updateTotalPrice() {
      this.totalPrice = this.roomsList.reduce((a, b) => +a + +b.totalPrice, 0);
      window.sessionStorage.setItem('production', JSON.stringify(this.product));
      this.$store.dispatch('UPDATE_ROOM_LIST', { roomList: this.roomsList });
    },
    updatedActiveHotel(pHotel) {
      this.product.activeHotel[0] = pHotel;
      let grade = Number(pHotel.categoryCode.replace('EST', ''));
      if (Number.isNaN(grade)) grade = 0;
      this.product.hotels[0].grade = grade;
      this.$store.commit('SET_PRODUCT', this.product);
    },
    async gotoBook() {
      const extendProduct = this.product;
      extendProduct.hotel = this.roomsList;
      extendProduct.price = this.totalPrice;
      window.sessionStorage.setItem('production', JSON.stringify(extendProduct));
      const currentHotel = this.product.activeHotel[0];
      const bookingData = {
        agentScreenResolultion: this.device,
        grandTotalAfterDiscount: this.totalPrice,
        currencyCode: this.hotel.currency,
        couponCode: null,
        hotel: {
          hotelCode: currentHotel.code,
          hotelName: currentHotel.name,
          checkinDate: this.hotel.stay.checkIn,
          checkoutDate: this.hotel.stay.checkOut,
          remarkInVoucher: '',
          cityCode: currentHotel.destinationCode,
          cityName: currentHotel.destinationName,
          rooms: [],
        },
        paxList: [],
        payer: {
          name: '',
          email: '',
          mobile: '',
          creditCardExpirMonth: '',
          creditCardExpirYear: '',
          identificationNumber: '',
          noOfPayment: 1,
        },
      };
      let paxId = 0;
      const paxes = [];
      this.roomsList.forEach((room) => {
        const paxIds = [];
        for (let i = 0; i < room.adult; i += 1) {
          paxId += 1;
          paxes.push({
            id: paxId,
            paxType: 'AD',
            lastName: '',
            firstName: '',
            birthDate: '',
            email: '',
            phone: '',
          });
          paxIds.push(paxes.length - 1);
        }
        for (let i = 0; i < room.child; i += 1) {
          paxId += 1;
          paxes.push({
            id: paxId,
            paxType: 'CH',
            lastName: '',
            firstName: '',
            birthDate: '',
            email: '',
            phone: '',
          });
          paxIds.push(paxes.length - 1);
        }
        for (let i = 0; i < room.infant; i += 1) {
          paxId += 1;
          paxes.push({
            id: paxId,
            paxType: 'CH',
            lastName: '',
            firstName: '',
            birthDate: '',
            email: '',
            phone: '',
          });
          paxIds.push(paxes.length - 1);
        }
        bookingData.hotel.rooms.push({
          rateKey: room.rate.rateKey,
          totalAfterDiscount: room.totalPrice,
          discountPercent: null,
          totalOriginal: room.oldTotal,
          nett: room.price,
          currencyCode: room.currency,
          roomCode: room.roomType.value,
          roomName: room.roomType.text,
          basisCode: room.roomBasisCode,
          basisName: room.roomBaiss,
          priceListCode: null,
          adults: room.adult,
          children: room.child,
          infant: room.infant,
          paxIds,
        });
      });
      bookingData.paxList = paxes;

      const storeData = {
        data: bookingData,
        sendingStage: 'init',
      };
      const response = await this.$store.dispatch(
        'SEND_BOOKING_DATA_HOTEL_ONLY',
        storeData,
      );
      if (response.success) {
        window.sessionStorage.setItem('BookingDataID', response.id);
        window.sessionStorage.setItem('retryState', 'null');
        const body = {
          hotelId: extendProduct.activeHotel[0].code,
        };
        this.$router.push({ path: '/booking/hotelbeds', query: body });
      } else {
        window.sessionStorage.setItem('BookingDataID', '');
      }
    },
  },
};
</script>

<style scoped>
button:disabled,
[type='button']:disabled,
[type='reset']:disabled,
[type='submit']:disabled {
  cursor: default;
  opacity: 0.2;
}
</style>
<style>
button.js-del-row {
  top: -5px;
}

@media (max-width: 600px) {
  div[id^='upgrade-'] .modal-dialog {
    width: 96%;
  }
  div[id^='upgrade-'] table th,
  div[id^='upgrade-'] table td {
    padding: 2px;
  }
}

@media (max-width: 768px) {
  .flightpagelist.filterItem {
    margin-top: 1em;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    padding: 5px;
  }
}
</style>
